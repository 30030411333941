import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useJobListingQuery = () => {
  const { allSanityJobListing } = useStaticQuery(graphql`
    query {
      allSanityJobListing(sort: { fields: orderRank, order: ASC }) {
        nodes {
          listingIntro
          location
          title
          slug {
            current
          }
          listingImage {
            ...ImageWithPreview
          }
          details {
            description: _rawDescription(resolveReferences: { maxDepth: 10 })
            offers: _rawOffers(resolveReferences: { maxDepth: 10 })
            requirements: _rawRequirements(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  `);
  return allSanityJobListing || {};
};

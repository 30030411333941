import React, { useEffect, useState } from 'react';
import WhiteVector from '@svg/whitevector.svg';
import cx from 'classnames';
import { Link as ScrollLink } from 'react-scroll';

const StandardButton = ({ buttonName, colour, aLink }) => {
  const [buttonHovered, setButtonHovered] = useState(false);
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' && window.innerHeight,
  );

  useEffect(() => {
    setWindowHeight(typeof window !== 'undefined' && window.innerHeight);
  }, []);

  return (
    <ScrollLink
      to={aLink ? aLink : 'top'}
      offset={aLink ? 0 : windowHeight}
      smooth={true}
      onMouseEnter={() => setButtonHovered(true)}
      onMouseLeave={() => setButtonHovered(false)}
      className={cx(
        '  w-[fit-content] flex mt-5 cursor-pointer transition-all ease-in-out duration-700',
        {
          'button-transparent ': !colour,
          'button-blue': colour === 'Blue',
          'button-red': colour === 'Red',
          'button-navy': colour === 'Navy',
          'button-dark-red': colour === 'dark-red',
          '!bg-blue/25': buttonHovered,
        },
      )}
      style={{
        WebkitBackdropFilter: 'blur(7px)',
      }}>
      <div className='blockH8 font-medium  self-center inline-block pr-4 '>
        {buttonName}
      </div>
      <div
        className={cx(
          'inline-block  p-3 rounded-full transition-all ease-in-out duration-700 ',
          {
            'bg-white/20': !colour,
            'bg-blue/20': colour === 'Blue',
            'bg-red/20': colour === 'Red',
            'bg-navy/20': colour === 'Navy',
            'bg-dark-red/20': colour === 'dark-red',
            '!bg-blue': buttonHovered,
          },
        )}>
        <WhiteVector
          className={cx('w-4 h-4', {
            'svg-blue': colour === 'Blue',
            'svg-red': colour === 'Red',
            'svg-navy': colour === 'Navy',
            'svg-dark-red': colour === 'dark-red',
          })}
        />
      </div>
    </ScrollLink>
  );
};

export default StandardButton;
